import React from "react"
import SectionHeader from "../SectionHeader/SectionHeader"
import "./Menu.css"

const Menu = props => {
  const renderMenuBody = () => {
    let categories = []
    let html_menu = props.rest.html_menu

    if (html_menu === null) {
      html_menu = {}
    }

    for (let i in html_menu) {
      let category = html_menu[i]
      categories.push(
        <div key={categories.length} className="MenuCategoryContainer">
          <h4
            className="MenuCategory"
            style={{ color: props.rest.hex_color_secondary }}
          >
            {category.name}
          </h4>
          {category.items.map((item, i) => {
            return (
              <div key={i} className="MenuItem">
                {item.name}
                <span
                  style={{ color: props.rest.hex_color_primary }}
                  className="MenuItemPrice"
                >
                  {item.price}
                </span>
              </div>
            )
          })}
        </div>
      )
    }
    return categories
  }

  if (props.rest.menu_header && props.rest.menu_header != "") {
    return (<div className="MenuContainer">
      <SectionHeader title={props.rest.menu_header} rest={props.rest} />
      {renderMenuBody()}
      </div>)
  } else {
    return <div />
  }
}

export default Menu
