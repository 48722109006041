import React from "react"
import AnalyticsContextHOC from "../AnalyticsContext/AnalyticsContextHOC.js"
import "./SectionHeader.css"

const SectionHeader = props => {
  const handleClick = () => {
    props.analytics.send(props.rest.name, "PDF Menu")
  }

  const renderMenuPDF = () => {
    if (props.title === "Menu" && props.rest.pdf_menu && props.rest.pdf_menu != "") {
      return (
        <a
          onClick={handleClick}
          className="SectionHeaderMenuLink"
          href={props.rest.pdf_menu}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="./icons/pdf.png"
            className="SectionHeaderMenuImg"
            alt="menu"
          />
          {window.innerWidth < 800 && <br />}
          Click Here for PDF Menu
        </a>
      )
    }
  }

  return (
    <div>
      <h1
        className="SectionHeaderContainer"
        style={{
          background: props.rest.hex_color_primary,
          boxShadow: `-8px -8px 0px 0px ${props.rest.hex_color_secondary}`
        }}
      >
        {props.title}
      </h1>
      {renderMenuPDF()}
    </div>
  )
}

export default AnalyticsContextHOC(SectionHeader)
